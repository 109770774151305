import { useState } from 'react';

import {
  Banner,
  Paragraph,
  List,
  ListItem,
  TextLink,
  Button as DarriusButton,
} from 'darrius';

import { type TParagraphSize } from 'darrius/build/types/components/molecules/Paragraph/Paragraph';

import classnames from 'classnames';

interface ILinkListItem {
  id: string | number;
  text: string;
  url: string;
}

interface ILinkListProps {
  className?: string;
  title: string;
  titleLevel?: TParagraphSize;
  links: ILinkListItem[];
}

const { Root: Button } = DarriusButton;

const LinkList = ({
  className = '',
  title,
  titleLevel = '2',
  links,
}: ILinkListProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const shouldHideItemsMobile = links.length > 10;

  const bannerClassNames = classnames('link-list', className, {
    'hide-items-mobile': !isOpen && shouldHideItemsMobile,
  });

  return (
    <Banner className={bannerClassNames}>
      <Paragraph title={title} size={titleLevel} />

      <List className="link-list__items">
        {links.map((link) => (
          <ListItem key={link.id} className="link-list__item">
            <TextLink text={link.text} url={link.url} />
          </ListItem>
        ))}
      </List>

      {shouldHideItemsMobile && (
        <Button
          className="link-list__show-items-button clear"
          aria-hidden="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? 'Mostrar menos' : 'Mostrar mais'}
        </Button>
      )}
    </Banner>
  );
};

export default LinkList;
