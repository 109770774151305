import Image from 'next/image';
import {
  Banner,
  Card,
  CardBody,
  Paragraph,
  Button as DarriusButton,
} from 'darrius';

import { Carousel } from '../../atoms';
import { type IMainServices } from '../../../locales/@interfaces/shared/mainServices';
import { getBlurDataURL } from '../../../utils/getBlurDataURL';

import styles from './MainServices.module.scss';
import Link from 'next/link';

interface IMainServicesProps {
  data: IMainServices;
}

const { Root: Button } = DarriusButton;

const MainServices = ({
  data: { title, subtitle, items, requestText },
}: IMainServicesProps) => (
  <Banner as="section" className="main-services full">
    <Paragraph size="2" text={subtitle} title={title} headline />

    <Carousel
      className={`main_services space-top space-bottom ${styles.main_services} `}
    >
      {items.map((service) => (
        <Card key={service.title}>
          <Image
            src={service.img}
            alt={service.title}
            width={316}
            height={171}
            placeholder="blur"
            blurDataURL={getBlurDataURL()}
            className="responsive-image"
          />

          <CardBody className="small">
            <h2
              className={`main_services__services-title ${styles.main_services__services_title} `}
            >
              {' '}
              {service.title}
            </h2>

            <Link href={service.link} passHref legacyBehavior>
              <Button as="a" variation="clear" fullWidth>
                {requestText}
              </Button>
            </Link>
          </CardBody>
        </Card>
      ))}
    </Carousel>
  </Banner>
);

export default MainServices;
