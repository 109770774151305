/**
 * We use "window.location.replace" instead of the "next router" to navigate because
 * we had problems with tracking the user next route, if you want more details,
 * we have an ADR that explains the motivation and proposed solution:
 *
 * URL: https://www.notion.so/getninjas/Substituindo-o-router-push-do-NextJS-pelo-window-location-href-10445141dd844940b2ea125c694069ae?pvs=4
 */
export function navigate(to: string) {
  window.location.replace(to);
}
