import { Banner, Button as DarriusButton } from 'darrius';
import Image from 'next/image';

import { imgPrefix } from '../../../utils';

import styles from './KeepInPage.module.scss';

interface IKeepInPageProps {
  link: string;
  text: string;
}

const { Root: Button } = DarriusButton;

const KeepInPage = ({ link, text }: IKeepInPageProps) => (
  <Banner className={`full keep-in-page ${styles.container}`}>
    <div className={styles.card}>
      <div className={styles.image}>
        <Image
          src={imgPrefix('/images/home/precisa-de-ajuda.svg')}
          alt="Precisa de ajuda?"
          width="112"
          height="70"
        />
      </div>

      <h2 className={styles.title}>Precisando de ajuda?</h2>

      <p className={styles.text}>
        Conte conosco para conectar você aos melhores profissionais e receba até{' '}
        <br /> <strong>4 orçamentos grátis</strong>.
      </p>

      <Button
        as="a"
        href={link}
        variation="inverse"
        fullWidth
        className={styles['main-button']}
      >
        {text}
      </Button>
    </div>
  </Banner>
);

export default KeepInPage;
