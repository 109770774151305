import Image from 'next/image';

import { Avatar, Stars } from 'darrius';

import { imgPrefix } from '../../../utils';

import styles from './ProCard.module.scss';

interface IProCardProps {
  link: string;
  name: string;
  avatar: string;
  description: string;
  rating: {
    value: number;
    max: number;
  };
  recommendationCount: number;
  leadCount?: number;
  isVerified?: boolean;
  recentRecommendation: {
    author: string;
    text: string;
    rating: {
      value: number;
      max: number;
    };
  };
}

const ProCard = ({
  link,
  name,
  avatar,
  description,
  rating,
  recommendationCount,
  leadCount = null,
  isVerified = false,
  recentRecommendation,
}: IProCardProps) => {
  const ariaLabel = `Profissional ${name} com ${recommendationCount} recomendações`;

  return (
    <a
      href={link}
      className={`start-sm ${styles['pro-card']} `}
      aria-label={ariaLabel}
    >
      <div className={styles['pro-card__bio']}>
        <Avatar src={avatar} alt={name} size={72} />

        <div className={styles['pro-card__bio-info']}>
          <p className={styles['pro-card__name']}>{name}</p>

          <Stars quantity={rating.max} rating={rating.value} />

          <p
            className={styles['pro-card__recommendation-count']}
          >{`${recommendationCount} recomendações`}</p>
        </div>
      </div>

      <div className={styles['pro-card__about']}>
        <p className={styles['pro-card__about-title']}>Sobre o profissional</p>
        <p className={styles['pro-card__about-text']}>{description}</p>
      </div>

      <div className={styles['pro-card__badges']}>
        {isVerified && (
          <div
            className={styles['pro-card__badge']}
            data-testid="pro-card__badge-verified"
          >
            <Image
              src={imgPrefix('/images/category/verified-professional.svg')}
              width="20"
              height="20"
              alt="Profissional verificado"
            />

            <p className={styles['pro-card__badge-text']}>
              Profissional com <b>documentos verificados</b>.
            </p>
          </div>
        )}

        {leadCount && (
          <div
            className={styles['pro-card__badge']}
            data-testid="pro-card__badge-leads"
          >
            <Image
              src={imgPrefix('/images/category/negotiated-clients.svg')}
              width="20"
              height="20"
              alt="Clientes negociados"
            />

            <p className={styles['pro-card__badge-text']}>
              Já negociou com <b>{leadCount} clientes</b>.
            </p>
          </div>
        )}
      </div>

      <div className={styles['pro-card__recommendation']}>
        <p className={styles['pro-card__recommendation-header']}>
          Recomendação mais recente:
        </p>

        <p className={styles['pro-card__recommendation-text']}>
          {recentRecommendation.text}
        </p>

        <div className={styles['pro-card__author']}>
          <Stars
            quantity={recentRecommendation.rating.max}
            rating={recentRecommendation.rating.value}
          />

          <p className={styles['pro-card__author-text']}>
            por <b>{recentRecommendation.author}</b>
          </p>
        </div>
      </div>
    </a>
  );
};

export default ProCard;
